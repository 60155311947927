import React from "react"

import Layout from "../components/layout"

const text = `
    Mostafa Gaafar (Gafi) 👋

    Software Engineer based in London 🇬🇧               
                                                        
                                                        
<a href="https://twitter.com/imgaafar" target="_blank">Twitter</a>
<a href="https://linkedin.com/in/mostafamgaafar" target="_blank">LinkedIn</a>
<a href="https://medium.com/@mgaafar" target="_blank">Blog</a>
<a href="https://github.com/gaafar" target="_blank">Github</a>
<a href="https://stackoverflow.com/users/3716153/gaafar" target="_blank">Stack Overflow</a>
`

const IndexPage = () => (
  <Layout>
    <div style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: text }} />
  </Layout>
)

export default IndexPage
